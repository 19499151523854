<template>
  <u-dashboard-toolbar />
  <u-dashboard-panel-content
    :class="{
      'fixed': true,
      '-top-8': true,
      '-right-8': true,
      '-bottom-8': true,
      '-left-8': true,
      'p-4': true,
      'text-center': true,
      'flex': true,
      'flex-col': true,
      'justify-center': true,
      'justify-items-center': true,
      'items-center': true,
      'gap-y-4': true,
      'text-lg': true,
      'bg-white dark:bg-gray-900': true,
    }"
  >
    <u-icon
      name="i-ph-cactus"
      class="text-[128px] text-tsppink-500 dark:text-tsppink-400"
    />
    <h2 class="text-tsppink-500 dark:font-bold dark:text-tsppink-400">
      Page not found.
    </h2>
    <p class="font-medium">
      The page you're looking for either moved or does not exist.
    </p>
    <br>
    <u-button
      variant="link"
      to="/"
      class="p-0 font-bold"
    >
      Go to dashboard.
    </u-button>
  </u-dashboard-panel-content>
</template>

<script lang="ts" setup>
useHead({
  title: 'The Show Portal - Page Not Found',
})

const app = useApp()

app.data.title = ''
</script>
